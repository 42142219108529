<template>
  <!-- 主页为什么选择组件 -->
  <div class="id-photo">
    <input id="filed" ref="file" type="file" accept="image/*" hidden="" @change="filePreview" />
    <div class="list-wrap" v-if="!showAnimation">
      <ul v-for="item in idPhotoList" :key="item.id">
        <li class="list-item">
          <img :src="item.fileUrl" alt="">
          <div class="list-item-text">{{item.pictureType}}</div>
          <div class="cover">
            <el-button class="up-btn" type="primary" icon="el-icon-picture-outline" round @click="uploadBtn(item)">Upload</el-button>
          </div>
        </li>
      </ul>
    </div>

    <div class="picture-dialog-warp" v-else>
      <div class="picture-dialog-box" :class="[{ showAnimation: showAnimation },{ hideAnimate: !showAnimation }]">
        <div class="title">{{ title }}</div>
        <div class="img-warp">
          <div class="image-warp">
            <img class="path-img" v-if="originalPath" :src="originalPath" alt="" />
            <div class="loading-img" v-if="!originalPath"></div>
            <img :src="$getFile('ui/loading.png')" v-if="!originalPath" class="loading" alt="" />
          </div>
          <img class="icon_conversion" :src="$getFile('ui/icon-conversion.png')" alt="" />
          <div class="image-warp">
            <img class="path-img" :src="conversionPath" v-if="originalPath" alt="" />
            <div class="loading-img" v-if="!originalPath && !finished"></div>

            <img :src="$getFile('ui/loading.png')" v-if="!loading && !finished" class="loading" alt="" />
            <div class="progress-fixed" v-if="loading && !finished">
              <el-progress type="circle" class="progress-warp" :width="32" :show-text="false" color="#fff" :percentage="progress" />
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <div class="replace" @click="uploadBtn(false)">replace</div>
          <AButton custom-class="picture-btn" :disabled="true" type="primary" :loading="payLoading" text="Photo Repair" @click="submitForm" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getUploadPic } from '@/common/uploading'
export default {
  name: "SpeechToText",
  props: {
    expirationTime: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectItem: '',
      footerPath: 'pay',
      orderInfo: {},
      paySuccess: false,
      initTime: 70,
      finished: false,
      payLoading: true,
      title: 'Uploading photo...',
      loading: false,
      showAnimation: false,
      dialogVisible: false,
      conversionPath: '',
      originalPath: '',
      templatePath: '',
      progress: 0,
      timer: null,
      titleTimer: null,
      titleList: ['Photo Repair...', 'Colorizing image...', 'Balancing color effects...', "Restoring scratches..."],
      idPhotoList: []
    }
  },
  mounted() {
    this.info()
  },
  methods: {
    async info() {
      let res = await this.$api.GetIdPhotoInfo()
      this.idPhotoList = res.result
    },
    uploadBtn(info) {
      if (!info) {
        // 中断请求
        source.cancel('Operation canceled by the user.');
        this.showAnimation = false
        this.originalPath = ''
        this.conversionPath = ''
        return
      }
      this.selectItem = info
      // console.log(info, ' select info >>>>>')
      document.getElementById('filed').click()
    },
    async filePreview(e) {
      if (!this.expirationTime) {
        this.$message({
          showClose: true,
          message: 'You are not a member yet and cannot use this feature temporarily!',
          type: 'error'
        });
        return
      }
      try {
        this.select = true
        var files = e.target.files[0]
        let types = ['image/jpeg', 'image/jpg', 'image/png']
        const isImage = types.includes(files.type)
        if (!isImage) {
          this.$message.error('This format is currently not supported')
          return
        }
        setTimeout(() => {
          this.showAnimation = true
        }, 100)
        this.finished = false
        this.payLoading = true
        // localStorage.setItem('orderInfo', '')
        // const order = await this.$api.createOrder({ type: 7 })
        // localStorage.setItem('orderInfo', JSON.stringify(order))
        // this.orderInfo = order
        const file = await getUploadPic(files)
        e.target.value = ''
        this.originalPath = file
        this.conversionPath = file
        this.loading = true
        // if (!this.userInfo?.subStatus || this.userInfo?.subStatus !== 1) {
        //   this.payLoading = false
        // }
        this.changeTitle(0)
        this.setProgress()
        const info = {
          imgInPath: file,
          dpi: this.selectItem.dpi,
          bgColor: this.selectItem.bgColor,
          cutSizeX: this.selectItem.digitalWidth,
          cutSizeY: this.selectItem.digitalHeight,
          headHeightRatio: this.selectItem.headHeightRatio || null,
          topHairHeightRatio: this.selectItem.topHairHeightRatio || null,
          bottomEyeHeightRatio: this.selectItem.bottomEyeHeightRatio || null
        }
        let res = await this.$api.ProductionIdPhoto(info)
        if (res) {
          this.conversionPath = res.obj
          setTimeout(() => {
            this.payLoading = false
            this.finished = true
          }, 800)
        }
        // this.$api.orderProcess({
        //   orderId: order.id,
        //   pictureUrl: file,
        //   uid: order.uid,
        // }).then(ret => {
        //   setTimeout(() => {
        //     this.getOrderStatus()
        //   }, 1000)
        // })
      } catch (error) {
        console.log(error)
      }
    },
    changeTitle(num) {
      if (this.finished) {
        this.title = "Photo has been restored!"
        return
      }
      const list = this.titleList
      this.title = list[num]
      this.titleTimer = setTimeout(() => {
        num = num + 1
        if (num > list.length - 1) {
          num = 0
        }
        this.changeTitle(num)
      }, 2000)
    },
    //获取进度
    setProgress() {
      let minSlow = 60
      let maxSlow = 90
      this.timer = setTimeout(() => {
        this.progress = this.progress < 90 ? this.progress + 1 : 90
        if (this.progress > minSlow && this.progress < maxSlow) {
          if (!this.finished) {
            this.initTime += 10
          } else {
            //此处可重置上传进度增长周期
          }
        }
        if (this.finished) {
          this.progress = 100
          //处理完成
          clearTimeout(this.timer)
          clearTimeout(this.titleTimer)
          this.changeTitle(0)
          this.timer = null //清除定时器，避免导致内存溢出
          return
        }
        this.setProgress()
      }, this.initTime)
    },
    // 提交
    submitForm() {
      if (this.conversionPath) {
        window.open(this.conversionPath)
      }
    },


  },
};
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .id-photo {
    .list-wrap {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      ul {
        list-style: none;
        margin-top: 10px;
        .list-item {
          position: relative;
          width: 152px;
          height: 215px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin-left: 10px;
          text-align: center;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
          img {
            width: 140px;
            height: 168px;
          }
          .list-item-text {
            margin-top: 6px;
          }
          &:hover .cover {
            display: block;
          }
          .cover {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.3);
            .up-btn {
              position: absolute;
              bottom: 30%;
              left: 50%;
              transform: translate(-50%);
            }
          }
        }
      }
    }
    .picture-dialog-warp {
      width: 100%;
      height: 100%;
      text-align: center;
      .select-file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 480px;
        background: #dcdfe6;
        .select-btn {
          width: 320px;
          height: 200px;
          border: 3px dashed #000;
          border-radius: 15px;
          line-height: 200px;
          cursor: pointer;
          font-size: 22px;
          &:hover {
            color: #fff;
            border: 3px dashed #fff;
          }
        }
      }
      .picture-dialog-box {
        padding: 10px 0 30px;
        background: #f2f3f5;

        &.showAnimation {
          animation: wrapper-gradient 0.25s linear;
        }
        &.hideAnimate {
          animation: hide-gradient 0.25s linear;
          animation-fill-mode: forwards; //  动画停留在最后一刻，也就是隐藏
        }

        .title {
          font-size: 36px;
          font-weight: bold;
          font-family: Manrope-Bold, Manrope;
          color: #333333;
          min-height: 50px;
          line-height: 50px;
        }
        .img-warp {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .icon_conversion {
            width: 64px;
            margin: 0 12px;
          }
          .image-warp {
            width: 240px;
            display: flex;
            align-content: center;
            justify-content: center;
            height: 240px;
            border-radius: 8px;
            border: 1px solid rgba(222, 226, 230, 0.41);
            position: relative;
            .path-img {
              width: 100%;
              border-radius: 8px;
              object-fit: contain;
            }
            .loading {
              width: 56px;
              height: 56px;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 10;
              animation: rotate 2s linear infinite;
              -webkit-animation: rotate 2s linear infinite;
            }
            .progress-fixed {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              background: rgba($color: #000000, $alpha: 0.3);
              .progress-warp {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 11;
                width: 56px;
                height: 56px;
                ::v-deep path:first-child {
                  stroke: rgba(0, 0, 0, 0.5);
                }
              }
            }
            .loading-img {
              position: absolute;
              left: 0;
              top: 0;
              background: #cccccc;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              z-index: 1;
            }
          }
        }
        .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          .replace {
            width: 200px;
            height: 54px;
            line-height: 54px;
            border-radius: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            margin-right: 20px;
            font-size: 20px;
            font-family: Manrope-Bold, Manrope;
            cursor: pointer;
          }
          .picture-btn {
            width: 200px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .id-photo {
    margin-top: 0.2rem;
    .list-wrap {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      ul {
        list-style: none;
        margin-top: 0.1rem;
        .list-item {
          position: relative;
          width: 1.8rem;
          height: 2.65rem;
          padding: 0.1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin-left: 0.1rem;
          text-align: center;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
          font-size: 0.12rem;
          margin-bottom: 0.1rem;
          img {
            width: 1.6rem;
            height: 2rem;
          }
          &:hover .cover {
            display: block;
          }
          .cover {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            .up-btn {
              position: absolute;
              bottom: 30%;
              left: 50%;
              transform: translate(-50%);
            }
          }
        }
      }
    }
    .picture-dialog-warp {
      margin-top: 0.5rem;
      .select-file {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 4.8rem;
        background: #dcdfe6;
        .select-btn {
          width: 3.2rem;
          height: 2rem;
          border: 3px dashed #000;
          border-radius: 0.15rem;
          line-height: 2rem;
          font-size: 0.28rem;
          &:active {
            color: #fff;
            border: 3px dashed #fff;
          }
        }
      }
      .picture-dialog-box {
        width: 100%;
        background: #ffffff;
        text-align: center;
        &.showAnimation {
          animation: wrapper-gradient 0.25s linear;
        }
        &.hideAnimate {
          animation: hide-gradient 0.25s linear;
          animation-fill-mode: forwards; //  动画停留在最后一刻，也就是隐藏
        }

        .title {
          font-size: 0.36rem;
          font-weight: bold;
          font-family: Manrope-Bold, Manrope;
          color: #333333;
          min-height: 0.5rem;
          line-height: 0.5rem;
        }
        .img-warp {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;
          .icon_conversion {
            width: 0.64rem;
            margin: 0 0.12rem;
          }
          .image-warp {
            width: 2.4rem;
            display: flex;
            align-content: center;
            justify-content: center;
            height: 2.4rem;
            border-radius: 0.08rem;
            border: 1px solid rgba(222, 226, 230, 0.41);
            position: relative;
            .path-img {
              width: 100%;
              border-radius: 0.08rem;
              object-fit: contain;
            }
            .loading {
              width: 0.56rem;
              height: 0.56rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 10;
              animation: rotate 2s linear infinite;
              -webkit-animation: rotate 2s linear infinite;
            }
            .progress-fixed {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              background: rgba($color: #000000, $alpha: 0.3);
              .progress-warp {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                z-index: 11;
                width: 0.56rem;
                height: 0.56rem;
                ::v-deep path:first-child {
                  stroke: rgba(0, 0, 0, 0.5);
                }
              }
            }
            .loading-img {
              position: absolute;
              left: 0;
              top: 0;
              background: #f1e5fa;
              width: 100%;
              height: 100%;
              border-radius: 0.08rem;
              z-index: 1;
            }
          }
        }
        .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;
          margin-bottom: 0.2rem;

          .replace {
            width: 2rem;
            height: 0.8rem;
            line-height: 0.8rem;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            margin-right: 0.2rem;
            font-size: 0.3rem;
            font-family: Manrope-Bold, Manrope;
            border: 1px solid #eee;
          }
          .picture-btn {
            padding: 0 0.3rem;
          }
        }
      }
    }
  }
}
</style>
