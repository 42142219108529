import request from '@/utils/axios'

// 登录
export function Login(data) {
  return request({
    url: '/v1/user/session/login',
    method: 'POST',
    data
  })
}

// 注册
export function Register(data) {
  return request({
    url: '/v1/user/register',
    method: 'POST',
    data
  })
}

// 注销登录
export function Logout(params) {
  return request({
    url: '/v1/user/session/logout',
    method: 'GET',
    params
  })
}

// 修改密码
// export function ChangePassword(data) {
//   return request({
//     url: '/v1/user/password',
//     method: 'PUT',
//     data
//   })
// }

// 获取用户信息
export function GetUserInfo(params) {
  return request({
    url: '/v1/user/info',
    method: 'GET',
    params
  })
}

// 修改用户信息
export function SetUserInfo(data) {
  return request({
    url: '/v1/user/info',
    method: 'PUT',
    data
  })
}

// 取消订阅
export function Unsubscribe(data = {}) {
  return request({
    url: '/v1/user/cancel/subscription',
    method: 'PUT',
    data
  })
}

// 获取证件照详情
export function GetIdPhotoInfo(params) {
  return request({
    url: '/v1/pictureSetup/all',
    method: 'GET',
    params
  })
}

// 证件照制作
export function ProductionIdPhoto(data) {
  return request({
    url: '/v1/aiphoto/idPhoto',
    method: 'POST',
    data
  })
}