import { getOSSSignature } from '@/api/oss';
import axios from 'axios';

export const getUploadPic = async (file) => {
  const res = await getOSSSignature({ _model: 'src' })
  // console.log(res, file, " file >>>")
  const fileNames = file.name.split('.')
  const ext = fileNames[fileNames.length - 1]
  return await directUploadFile2OSS(res, file, generateUniqueId(), ext)
}

const directUploadFile2OSS = (ossSignature, file, filename, ext) => {
  return new Promise((resolve, reject) => {
    const date = new Date()
    const s = date.getTime()
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    const ossFileKey =
      ossSignature.obj.dir +
      '/' +
      y +
      '/' +
      m +
      '/' +
      d +
      '/' +
      s +
      '_' +
      filename +
      '.' +
      ext

    // 添加配置参数
    const ossData = new FormData()
    ossData.append('OSSAccessKeyId', ossSignature.obj.accessid)
    ossData.append('policy', ossSignature.obj.policy)
    ossData.append('Signature', ossSignature.obj.signature)
    ossData.append('key', ossFileKey)
    ossData.append('success_action_status', 201) // 指定返回的状态码
    ossData.append('file', file, filename)
    axios.post(ossSignature.obj.host, ossData)
      .then(res => {
        console.log(res, "res >>>")
        if (res.status === 201) {
          const img = ossSignature.obj.host + '/' + ossFileKey
          resolve(img)
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err>>>")
        reject()
      })
  })
}


function generateUniqueId() {
  const abc = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'g',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  const [max, min] = [
    Math.floor(Math.random() * (10 - 7 + 1) + 1),
    Math.floor(Math.random() * (17 - 10 + 1) + 17)
  ]
  return abc
    .sort(() => 0.4 - Math.random())
    .slice(max, min)
    .slice(0, 8)
    .join('')
}
