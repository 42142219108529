import request from '@/utils/axios'
// 获取oss上传地址
export function getOSSSignature(params) {
 return request({
  url: '/v1/oss/aiphoto/signature',
  method: 'get',
  params,
  custom: {
   headers: {
    website: 'recovery',
    phone_os: 2,
    apk_version_code: 10004
   }
  },
 })
}
// 语音转文字
export function speechToText(data) {
 return request({
  url: '/v1/convert/speechToText',
  method: 'post',
  data,
  custom: {
   headers: {
    website: 'recovery',
    phone_os: 2,
    apk_version_code: 10004
   }
  },
 })
}